var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mnddetail"},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"header-box"},[_vm._m(0),_c('div',{staticClass:"right"},[_c('div',{staticClass:"right-box"},[_c('span',{staticClass:"span1"},[_vm._v("倒计时:")]),_c('span',[_vm._v(_vm._s(_vm.times))]),_c('span',{staticStyle:{"margin-left":"5px","cursor":"pointer"},on:{"click":_vm.gotoDetail}},[_vm._v("马上进入")])])])]),_c('div',{staticClass:"content-title"},[_vm._v(" 全国会计专业技术初级资格无纸化考试重要提示 ")]),_c('div',{staticClass:"content-body"},[(_vm.xuzhiVisible)?_c('div',{staticClass:"content-body-top"},[_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8),_vm._m(9),_vm._m(10),_vm._m(11),_vm._m(12),_vm._m(13)]):_vm._e(),(!_vm.xuzhiVisible)?_c('div',{staticClass:"content-body-top"},[_c('img',{attrs:{"src":require("../../assets/tiku/001.jpeg")}}),_c('br'),_c('img',{attrs:{"src":require("../../assets/tiku/002.jpeg")}}),_c('br'),_c('img',{attrs:{"src":require("../../assets/tiku/003.jpeg")}}),_c('br'),_c('img',{attrs:{"src":require("../../assets/tiku/004.jpeg")}}),_c('br'),_c('img',{attrs:{"src":require("../../assets/tiku/005.jpeg")}}),_c('br'),_c('img',{attrs:{"src":require("../../assets/tiku/006.jpeg")}}),_c('br'),_c('img',{attrs:{"src":require("../../assets/tiku/007.jpeg")}}),_c('br'),_c('img',{attrs:{"src":require("../../assets/tiku/008.jpeg")}}),_c('br'),_c('img',{attrs:{"src":require("../../assets/tiku/009.jpeg")}}),_c('br'),_c('img',{attrs:{"src":require("../../assets/tiku/010.jpeg")}}),_c('br'),_c('img',{attrs:{"src":require("../../assets/tiku/011.jpeg")}}),_c('br')]):_vm._e(),_c('div',{staticClass:"bottom-btn"},[_c('div',{staticStyle:{"margin-right":"5px"},on:{"click":_vm.xuzhi}},[_vm._v("考生须知")]),_c('div',{staticStyle:{"margin-left":"5px"},on:{"click":_vm.shuoming}},[_vm._v("操作说明")])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"left"},[_c('img',{attrs:{"src":require("../../assets/tiku/detailpeople.jpg")}}),_c('div',{staticClass:"info-box"},[_c('div',[_c('span',[_vm._v("姓名:")]),_c('span',[_vm._v("XXX")])]),_c('div',[_c('span',[_vm._v("姓别:")]),_c('span',[_vm._v("X")])]),_c('div',[_c('span',[_vm._v("准考证号:")]),_c('span',[_vm._v("XXXXXXXXXXX")])]),_c('div',[_c('span',[_vm._v("证件号码:")]),_c('span',[_vm._v("XXXXXXXXXXXXXXXXXX")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"top1"},[_vm._v(" 2024年度全国会计专业技术初级资格考试"),_c('br'),_vm._v(" 考生须知 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"top2"},[_c('span',{staticClass:"left"},[_vm._v("第一条")]),_c('span',{staticClass:"right"},[_vm._v("考试开始前30分钟，考生凭本人准考证和有效居民身份证（香港、澳门、台湾籍考生凭有效身份证明，下同）进入考场，完成电子签到后，按照监考人员指定的位置入座，并将准考证和有效居民身份证放置在桌面右上角。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"top2"},[_c('span',{staticClass:"left"},[_vm._v("第二条")]),_c('span',{staticClass:"right"},[_vm._v("考生进入考场时，应将准考证和有效居民身份证之外的其他物品(手机、电子设备应设置成关闭状态）存放在考场指定位置。携带准考证和有效居民身份证之外的物品（含电子、通讯、计算、存储等设备）进入考位，按违纪进行处理。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"top2"},[_c('span',{staticClass:"left"},[_vm._v("第三条")]),_c('span',{staticClass:"right"},[_vm._v("考生在完成签到后不得随意离开考场，如确有特殊情况需要暂时离开考场，必须经监考人员同意并由指定的监考人员陪同，凭本人有效居民身份证出入考场。考生在考试中途暂离考场，其离场时间计入本人的考试时间。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"top2"},[_c('span',{staticClass:"left"},[_vm._v("第四条")]),_c('span',{staticClass:"right"},[_vm._v("考场为考生统一提供演算纸笔。考试结束后，演算纸笔由监考人员统一收回，考生不得带出考场，违者按违纪进行处理。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"top2"},[_c('span',{staticClass:"left"},[_vm._v("第五条")]),_c('span',{staticClass:"right"},[_vm._v("考生入座后，在登录界面输入准考证号和居民身份证号登录考试系统，认真核对屏幕显示的本人相关信息，阅读并遵守《考生须知》及《操作说明》，等待考试开始。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"top2"},[_c('span',{staticClass:"left"},[_vm._v("第六条")]),_c('span',{staticClass:"right"},[_vm._v("考试时间以考试系统计时器为准。考试开始30分钟后，未签到的考生视为缺考，考试系统不再接受考生的登录。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"top2"},[_c('span',{staticClass:"left"},[_vm._v("第七条")]),_c('span',{staticClass:"right"},[_vm._v("进入考场后，考生应自觉遵守考场规定，维护考场秩序，尊重并自觉接受监考人员的监督和检查，保持考场安静，遇到问题应当举手示意。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"top2"},[_c('span',{staticClass:"left"},[_vm._v("第八条")]),_c('span',{staticClass:"right"},[_vm._v("考试过程中，如机器设备、网络、电力出现异常情况，考生应及时举手示意，请监考人员协调解决，不得自行处置，严禁自行关闭或重启考试机。因机器设备故障等客观原因导致答题时间出现损失，考生可以当场向监考人员提出补时要求，由监考人员根据有关规定予以处理。考试结束后，不再受理考生未当场提出的补时要求。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"top2"},[_c('span',{staticClass:"left"},[_vm._v("第九条")]),_c('span',{staticClass:"right"},[_vm._v("考试开始90分钟后，考生方可交卷离开考场。考生交卷后应当立即自行离开考场，严禁关闭考试机，不得在考场附近逗留、交谈或喧哗。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"top2"},[_c('span',{staticClass:"left"},[_vm._v("第十条")]),_c('span',{staticClass:"right"},[_vm._v("考试时间到，考试系统将自动为所有未交卷的考生统一交卷。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"top2"},[_c('span',{staticClass:"left"},[_vm._v("第十一条")]),_c('span',{staticClass:"right"},[_vm._v("考试期间，考生应按照考试系统要求进行操作，由于进行与考试无关的操作所造成的一切后果，由考生本人负责。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"top2"},[_c('span',{staticClass:"left"},[_vm._v("第十二条")]),_c('span',{staticClass:"right"},[_vm._v("考试期间违纪违规，依据《专业技术人员资格考试违纪违规行为处理规定》（人社部令第31号）进行处理。有严重和特别严重违纪违规行为的考生，将被记入会计资格考试诚信档案库，有关信息将向社会公告，并通知当事人所在单位，相关记录也将纳入全国信用共享平台。")])])
}]

export { render, staticRenderFns }