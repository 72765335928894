<template>
    <div class="mnddetail">
      <div class="content">
        <div class="header-box">
          <div class="left">
            <img src="../../assets/tiku/detailpeople.jpg" />
            <div class="info-box">
              <div>
                <span>姓名:</span><span>XXX</span>
              </div>
              <div>
                <span>姓别:</span><span>X</span>
              </div>
              <div>
                <span>准考证号:</span><span>XXXXXXXXXXX</span>
              </div>
              <div>
                <span>证件号码:</span><span>XXXXXXXXXXXXXXXXXX</span>
              </div>

            </div>
          </div>
          <div class="right">
            <div class="right-box">
                <span class="span1">倒计时:</span>
                <span>{{times}}</span>
                <span style="margin-left:5px;cursor: pointer;" @click="gotoDetail">马上进入</span>
            </div>
            <!-- <img src="../../assets/tiku/detailTime.jpg" /> -->

          </div>

        </div>
        <div class="content-title">
          全国会计专业技术初级资格无纸化考试重要提示
        </div>
        <div class="content-body">
          <div v-if="xuzhiVisible" class="content-body-top">
            <div class="top1">
              2024年度全国会计专业技术初级资格考试<br>
              考生须知
            </div>
            <div class="top2">
              <span class="left">第一条</span>
              <span class="right">考试开始前30分钟，考生凭本人准考证和有效居民身份证（香港、澳门、台湾籍考生凭有效身份证明，下同）进入考场，完成电子签到后，按照监考人员指定的位置入座，并将准考证和有效居民身份证放置在桌面右上角。</span>
            </div>

            <div class="top2">
              <span class="left">第二条</span>
              <span class="right">考生进入考场时，应将准考证和有效居民身份证之外的其他物品(手机、电子设备应设置成关闭状态）存放在考场指定位置。携带准考证和有效居民身份证之外的物品（含电子、通讯、计算、存储等设备）进入考位，按违纪进行处理。</span>
            </div>

            <div class="top2">
              <span class="left">第三条</span>
              <span class="right">考生在完成签到后不得随意离开考场，如确有特殊情况需要暂时离开考场，必须经监考人员同意并由指定的监考人员陪同，凭本人有效居民身份证出入考场。考生在考试中途暂离考场，其离场时间计入本人的考试时间。</span>
            </div>

            <div class="top2">
              <span class="left">第四条</span>
              <span class="right">考场为考生统一提供演算纸笔。考试结束后，演算纸笔由监考人员统一收回，考生不得带出考场，违者按违纪进行处理。</span>
            </div>

            <div class="top2">
              <span class="left">第五条</span>
              <span class="right">考生入座后，在登录界面输入准考证号和居民身份证号登录考试系统，认真核对屏幕显示的本人相关信息，阅读并遵守《考生须知》及《操作说明》，等待考试开始。</span>
            </div>

            <div class="top2">
              <span class="left">第六条</span>
              <span class="right">考试时间以考试系统计时器为准。考试开始30分钟后，未签到的考生视为缺考，考试系统不再接受考生的登录。</span>
            </div>

            <div class="top2">
              <span class="left">第七条</span>
              <span class="right">进入考场后，考生应自觉遵守考场规定，维护考场秩序，尊重并自觉接受监考人员的监督和检查，保持考场安静，遇到问题应当举手示意。</span>
            </div>

            <div class="top2">
              <span class="left">第八条</span>
              <span class="right">考试过程中，如机器设备、网络、电力出现异常情况，考生应及时举手示意，请监考人员协调解决，不得自行处置，严禁自行关闭或重启考试机。因机器设备故障等客观原因导致答题时间出现损失，考生可以当场向监考人员提出补时要求，由监考人员根据有关规定予以处理。考试结束后，不再受理考生未当场提出的补时要求。</span>
            </div>

            <div class="top2">
              <span class="left">第九条</span>
              <span class="right">考试开始90分钟后，考生方可交卷离开考场。考生交卷后应当立即自行离开考场，严禁关闭考试机，不得在考场附近逗留、交谈或喧哗。</span>
            </div>

            <div class="top2">
              <span class="left">第十条</span>
              <span class="right">考试时间到，考试系统将自动为所有未交卷的考生统一交卷。</span>
            </div>

            <div class="top2">
              <span class="left">第十一条</span>
              <span class="right">考试期间，考生应按照考试系统要求进行操作，由于进行与考试无关的操作所造成的一切后果，由考生本人负责。</span>
            </div>

            <div class="top2">
              <span class="left">第十二条</span>
              <span class="right">考试期间违纪违规，依据《专业技术人员资格考试违纪违规行为处理规定》（人社部令第31号）进行处理。有严重和特别严重违纪违规行为的考生，将被记入会计资格考试诚信档案库，有关信息将向社会公告，并通知当事人所在单位，相关记录也将纳入全国信用共享平台。</span>
            </div>
          </div>
          <div v-if="!xuzhiVisible" class="content-body-top">
            <img src="../../assets/tiku/001.jpeg" /><br>
            <img src="../../assets/tiku/002.jpeg" /><br>
            <img src="../../assets/tiku/003.jpeg" /><br>
            <img src="../../assets/tiku/004.jpeg" /><br>
            <img src="../../assets/tiku/005.jpeg" /><br>
            <img src="../../assets/tiku/006.jpeg" /><br>
            <img src="../../assets/tiku/007.jpeg" /><br>
            <img src="../../assets/tiku/008.jpeg" /><br>
            <img src="../../assets/tiku/009.jpeg" /><br>
            <img src="../../assets/tiku/010.jpeg" /><br>
            <img src="../../assets/tiku/011.jpeg" /><br>
            <!-- <img src="../../assets/tiku/012.jpeg" /><br>
            <img src="../../assets/tiku/013.jpeg" /><br> -->

          </div>
          <div class="bottom-btn">
            <div style="margin-right:5px;" @click="xuzhi">考生须知</div>
            <div style="margin-left:5px;" @click="shuoming">操作说明</div>
          </div>
        </div>

      </div>
     
    </div>
  </template>
  
  <script>
  
  export default {
    name: 'mnddetail',
    components: {},
    
    watch: {
      
    },
    data() {
      return {
        xuzhiVisible:true,
        times:null,
        time:null,
        timerFn:null
        
        
      }
    },
    destroyed(){
      clearTimeout(this.timerFn);
    },
    mounted(){
     this.delTime();
    },
    methods: {
      xuzhi(){this.xuzhiVisible = true},
      shuoming(){this.xuzhiVisible = false},
      formatSeconds(value) {
        let res = parseInt(value);
        let h = Math.floor(res / 3600) < 10 ? '0' + Math.floor(res / 3600) : Math.floor(res / 3600);
        let m = Math.floor(res / 60 % 60) < 10 ? '0' + Math.floor(res / 60 % 60) : Math.floor(res / 60 % 60);
        let s = Math.floor(res % 60) < 10 ? '0' + Math.floor(res % 60) : Math.floor(res % 60);

        let str = '';
        str += `${h}:`;
        str += `${m}:`;
        str += s;
        return str;
      },
      delTime(){
        this.time = 60;
        this.times = this.formatSeconds(this.time);
        this.countDown();
      },
      gotoDetail(){
        this.$router.push({
          path:'/mnjktiku',
          query: {
            tabIndex: this.$route.query.tabIndex,
            testPaperId: this.$route.query.testPaperId,
            type:this.$route.query.type
          }
        })

      },
      countDown() {
        this.timerFn = setTimeout(() => {
          this.time -= 1;
          this.times = this.formatSeconds(this.time);
          
          if (this.time <= 0) {
             //跳转
             this.$router.push({
                path:'/mnjktiku',
                query: {
                    tabIndex: this.$route.query.tabIndex,
                    testPaperId: this.$route.query.testPaperId,
                    type:this.$route.query.type
                }
            })
          }else {
            this.countDown();
          }
        }, 1000);
      },

      
    }
  }
  </script>
  
  <style lang="less" scoped>
  .mnddetail {
    width:100%;
    height: 100vh;
    .content{
      margin-top: 20px;
      width:calc(100% - 400px);
      margin-left: 200px;
      height: 100vh;
      background-image: url("../../assets/tiku/detailback.jpg");
      background-size: cover;
      background-color: #f5f8fd;
      .header-box{
        width: 100%;
        height: 155px;
        display: flex;
        justify-content: space-between;
        .right{
          display: flex;
          justify-content: right;
          align-items: center;
          
          width: 50%;
          height: 155px;
          .right-box{
            margin-right: 50px;
            width: 310px;
            height: 50px;
            background-image: url("../../assets/tiku/detailTime.jpg");
            text-align: left;
            line-height: 50px;
            color: #F00;
            font-size: 20px;
            font-weight: bolder;
            .span1{
              margin-left:65px;
            }

          }
        }
        .left{
          margin-left:10px;
          display: flex;
          flex-direction: row;
          img{
            height: 140px;
            margin-top:20px;
          }
          .info-box{
            margin-top: 20px;
            div{
              text-align: left;
              margin-top:5px;
              span{
                color: #0064aa;
                font-size: 18px;
                font-weight: bolder;
                line-height: 30px;
              }
            }
          }
        }
      }
      .content-title{
        width: 100%;
        height: 45px;
        line-height: 45px;
        text-align: center;
        font-size: 36px;
        font-weight: bold;
        color: #f3b60f;
        text-shadow: 2px 2px #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
        
      }
      .content-body{
        height: calc(100% - 220px);
        width: calc(100% - 80px);
        background-color: #ffffff;
        margin-left: 40px;
        .content-body-top{
          width: 100%;
          height: calc(100% - 50px);
          overflow: hidden;
          overflow-y: auto;
          .top1{
            margin-top:10px;
            text-align: center;
            font-size: 26px;
            font-weight: 600;
            font-family: 华文中宋;
            color: black;
          }
          .top2{
            font-size: 16px;
            text-align: left;
            margin-top:15px;
            margin-left: 10px;
            margin-right:10px;
            line-height: 30px;
            .left{
              font-weight: 600;
              
            }
            .right{
              margin-left: 10px;
              font-weight: 400;
            }
          }
        }
        .bottom-btn{
          width: 100%;
          height: 50px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 18px;
          color: #ffffff;
          font-weight: 600;
          line-height: 43px;
          div{
            width: 140px;
            height: 43px;
            background-image: url("../../assets/tiku/btnback.jpg");
            cursor: pointer;
          }

        }
      }

    }
    
  }
  </style>